<template>
  <v-container fluid>
    <v-card outlined class="mt-2 mx-4">
      <v-card-title class="text-body-1 px-3 py-2 lightgray">
        <v-text-field
          style="max-width: 300px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="vendas"
          :item-key="vendas.items"
          show-expand
          :items-per-page="15"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
        >
          <template v-slot:item.tipo ="{item}">
            <span v-if="item.origem_devolucao">Devolução </span>
            <span v-else-if="item.tipo_venda"> Venda </span>
            <span v-else-if="item.tipo">Ordem de Serviço</span>
          </template>



          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.status="{ item }">
            <span v-if="item.status === -1">Cancelada</span>
            <span v-if="item.status === 0"> Em Andamento </span>
            <span v-if="item.status === 1"> Pendente </span>
            <span v-if="item.status === 2"> Enviado Caixa </span>
            <span v-if="item.status === 3"> Em processamento </span>
            <span v-if="item.status === 4"> Devolução </span>
            <span v-if="item.status === 5"> Finalizada </span>
          </template>

          <template v-slot:item.pagare="{ item }">
            <v-btn
              v-if="item.condicao_pagamento === 2"
              icon
              @click.stop="openPdf(item)"
            >
              <v-icon>mdi-printer-outline</v-icon>
            </v-btn>
          </template>


          <template v-slot:item.valor="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.moeda_sigla }} {{ item.valor | guarani }}
            </span>
            <span v-else
              >{{ item.moeda_sigla }}{{ item.valor | currency }}
            </span>
          </template>

          <template v-slot:item.desconto="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.moeda_sigla }} {{ item.desconto | guarani }}
            </span>
            <span v-else
              >{{ item.moeda_sigla }}{{ item.desconto | currency }}
            </span>
          </template>

          <template v-slot:item.total="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.moeda_sigla }} {{ item.total | guarani }}
            </span>
            <span v-else
              >{{ item.moeda_sigla }}{{ item.total | currency }}
            </span>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="12" class="px-0">
              <v-data-table
                v-if="!loading && item.items.length"
                :headers="headersItems"
                :items="item.items"
                :items-per-page="15"
                hide-default-footer
              >
                <!-- <td :colspan="headers.length">More info about {{ item }}</td> -->
                <template v-slot:item.desconto="{ item }">
                  <span v-if="item.moeda_sigla === 'G$'">
                    {{ item.moeda_sigla }} {{ item.desconto | guarani }}
                  </span>
                  <span v-else
                    >{{ item.moeda_sigla }}{{ item.desconto | currency }}
                  </span>
                </template>
                <template v-slot:item.preco="{ item }">
                  <span v-if="item.moeda_sigla === 'G$'">
                    {{ item.moeda_sigla }} {{ item.preco | guarani }}
                  </span>
                  <span v-else
                    >{{ item.moeda_sigla }}{{ item.preco | currency }}
                  </span>
                </template>
              </v-data-table>
              <h3 v-else class="text-subtitle-1 text-center">
                Nenhum item nessa venda
              </h3>
            </td>
          </template>
        </v-data-table>
        <Carregando v-else />
      </v-card-text>
      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        title="PDF"
      />
    </v-card>
  </v-container>
</template>

<script>
import { getHistoricoVendas } from "@/api/clientes/clientes.js";
import { infoPagareVendaFinalizada } from "@/api/vendas/vendas.js";

const generatePagare = () =>
  import(
    /* webpackChunkName: "pagaréPdf" */ "@/views/dashboard/caixas-pdv/reports/pagarePdf.js"
  );
export default {
  name: "HistoricoVendas",

  components: {
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  props: {
    clienteId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      vendas: [],
      venda: {},
      venda_parcelas: [],
      search: "",
      pdfDialog: false,
      pdfData: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
          divider: true,
        },
        {
          text: this.$tc("global.numero"),
          value: "numero",
          divider: true,
        },
        {
          text: this.$tc("global.data"),
          value: "data",
          divider: true,
        },
        {
          text: this.$tc("global.tipo"),
          value: "tipo",
          divider: true,
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          divider: true,
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
          divider: true,
        },
        {
          text: "Total",
          value: "total",
          divider: true,
        },
        {
          text: "Pagare",
          value: "pagare",
          align: "center",
          divider: true,
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
      ];
    },

    headersItems() {
      return [
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
          sortable: false,
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
          sortable: false,
        },

        {
          text: this.$tc("global.desconto"),
          value: "desconto",
          sortable: false,
        },
        {
          text: this.$tc("global.preco"),
          value: "preco",
          sortable: false,
        },
      ];
    },

    cliente_id() {
      if (this.clienteId) {
        return this.clienteId;
      }
      return this.$route.params.id;
    },
  },

  methods: {
    async openPdf(item) {
      await infoPagareVendaFinalizada(item.id)
        .then((response) => {
          if (response.status === 200) {
            this.venda = response.data.result;
            if (this.venda.parcelas) {
              this.venda_parcelas = this.venda.parcelas;
            }
          }
        })
        .catch(() => {});

      generatePagare().then((module) => {
        let generate = module.generatePagare;
        generate(this.venda)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      });
    },

    fetchHistoricoVendas() {
      this.loading = true;
      this.vendas.cliente_id = getHistoricoVendas(
        `?cliente_id=${this.cliente_id}`
      )
        .then((response) => {
          this.vendas = response;
          this.loading = false;
        })
        .catch(() => {});
    },
  },

  mounted() {
    this.fetchHistoricoVendas();
  },
};
</script>

<style></style>
