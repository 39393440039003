var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-2 mx-4",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-body-1 px-3 py-2 lightgray"},[_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[(!_vm.loading)?_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"search":_vm.search.length >= 3 ? _vm.search : '',"items":_vm.vendas,"item-key":_vm.vendas.items,"show-expand":"","items-per-page":15,"sort-desc":true},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [(item.origem_devolucao)?_c('span',[_vm._v("Devolução ")]):(item.tipo_venda)?_c('span',[_vm._v(" Venda ")]):(item.tipo)?_c('span',[_vm._v("Ordem de Serviço")]):_vm._e()]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data,"dd/MM/yyyy"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === -1)?_c('span',[_vm._v("Cancelada")]):_vm._e(),(item.status === 0)?_c('span',[_vm._v(" Em Andamento ")]):_vm._e(),(item.status === 1)?_c('span',[_vm._v(" Pendente ")]):_vm._e(),(item.status === 2)?_c('span',[_vm._v(" Enviado Caixa ")]):_vm._e(),(item.status === 3)?_c('span',[_vm._v(" Em processamento ")]):_vm._e(),(item.status === 4)?_c('span',[_vm._v(" Devolução ")]):_vm._e(),(item.status === 5)?_c('span',[_vm._v(" Finalizada ")]):_vm._e()]}},{key:"item.pagare",fn:function(ref){
var item = ref.item;
return [(item.condicao_pagamento === 2)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openPdf(item)}}},[_c('v-icon',[_vm._v("mdi-printer-outline")])],1):_vm._e()]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(_vm._s(item.moeda_sigla)+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(_vm._s(item.moeda_sigla)+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.total))+" ")]):_c('span',[_vm._v(_vm._s(item.moeda_sigla)+_vm._s(_vm._f("currency")(item.total))+" ")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":12}},[(!_vm.loading && item.items.length)?_c('v-data-table',{attrs:{"headers":_vm.headersItems,"items":item.items,"items-per-page":15,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(_vm._s(item.moeda_sigla)+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.preco",fn:function(ref){
var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.preco))+" ")]):_c('span',[_vm._v(_vm._s(item.moeda_sigla)+_vm._s(_vm._f("currency")(item.preco))+" ")])]}}],null,true)}):_c('h3',{staticClass:"text-subtitle-1 text-center"},[_vm._v(" Nenhum item nessa venda ")])],1)]}}],null,false,3136641863)}):_c('Carregando')],1),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":"PDF"},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }